import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addPricingPackage } from '../../redux/apiCalls';
import { TextField, Button, Box, Container, Typography, Grid } from '@mui/material';

export default function NewPricePackage() {
    const dispatch = useDispatch();
  
    const [packageData, setPackageData] = useState({
        name: '',
        stripeSetupLink: '',
        features: ['', ''], // Start with two empty features by default
        pricing: '',
        webhookUrl: '' // Initial state for webhook URL
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPackageData({ ...packageData, [name]: value });
    };

    const handleAddFeature = () => {
        setPackageData({ ...packageData, features: [...packageData.features, ""] });
    };

    const handleFeatureChange = (index, value) => {
        const updatedFeatures = packageData.features.map((feature, i) => i === index ? value : feature);
        setPackageData({ ...packageData, features: updatedFeatures });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let cleanFeatures = packageData.features.filter(feature => feature.trim() !== "");
        let dataToSubmit = { ...packageData, features: cleanFeatures };
        dispatch(addPricingPackage(dataToSubmit));
        alert("Pricing Package created successfully!");
        setPackageData({ name: '', stripeSetupLink: '', features: ['', ''], pricing: '', webhookUrl: '' }); // Reset all form fields
    };

    return (
        <Container className='newPricePackage'>
            <Box sx={{ marginTop: 4 }}>
                <Typography variant="h4" gutterBottom>
                    New Pricing Package
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Webhook URL"
                                name="webhookUrl"
                                value={packageData.webhookUrl}
                                onChange={handleChange}
                                variant="outlined"
                                placeholder="Enter webhook URL"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Package Name"
                                name="name"
                                value={packageData.name}
                                onChange={handleChange}
                                required
                                variant="outlined"
                                placeholder="Enter package name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Stripe Setup Link"
                                name="stripeSetupLink"
                                value={packageData.stripeSetupLink}
                                onChange={handleChange}
                                required
                                variant="outlined"
                                placeholder="Enter Stripe setup link"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Pricing"
                                name="pricing"
                                type="number"
                                value={packageData.pricing}
                                onChange={handleChange}
                                required
                                variant="outlined"
                                placeholder="Enter package pricing"
                            />
                        </Grid>
                       
                        {packageData.features.map((feature, index) => (
                            <Grid item xs={12} key={index}>
                                <TextField
                                    fullWidth
                                    label={`Feature ${index + 1}`}
                                    value={feature}
                                    onChange={(e) => handleFeatureChange(index, e.target.value)}
                                    variant="outlined"
                                    placeholder="Specify feature"
                                />
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleAddFeature}
                                fullWidth
                            >
                                Add Feature
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                            >
                                Create
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Container>
    );
}
