import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import PricingPackage from "./pages/pricinigPackageList/PricingPackagesList";
import ProductPackages from "./pages/pricingPackages/PricingPackages";
import NewPricePackage from "./pages/newPricePackages/NewPricePackages";
import AgreementList from "./pages/Agreement/AgreementList/AgreementList";
import AgreementUpdate from "./pages/Agreement/Agreement/Agreement";
import NewAgreement from "./pages/Agreement/NewAgreement/NewAgreement";
import IndustryList from "./pages/Industries/IndustryList/IndustryList";
import IndustryUpdate from "./pages/Industries/Industry/Industry";
import NewIndustry from "./pages/Industries/NewIndustry/NewIndustry";
import ProposalList from "./pages/Proposal/proposalList/proposalList";
import ProposalUpdate from "./pages/Proposal/proposal/proposal";
import StrategyList from "./pages/cardList/CardList";
import StrategyDetails from "./pages/Card/Card";
import NewStrategy from "./pages/newCard/NewCard";

import TrashCanList from "./pages/Proposal/TrashProposal";
import CustomSidebar from "./components/sidebar/Sidebar";
import ProposalDetail from "./pages/Proposal/proposal/proposal";

function App() {
  return (
    <Router>
      <Switch>
          <>
            <Topbar />
            <div className="container">
              <Sidebar />
              <Route exact path="/">
                <ProposalList />
              </Route>
              <Switch>
              <Route path="/strategies/:strategyId">
                <StrategyDetails />
              </Route>
              <Route path="/strategies">
                <StrategyList />
              </Route>
              <Route path="/newStrategy">
                <NewStrategy />
              </Route>
              </Switch>
              <Switch>
              <Route path="/pricing-packages/:pricingPackageId">
                <ProductPackages />
              </Route>
              <Route path="/pricing-packages">
                <PricingPackage />
              </Route>
              <Route path="/newprice-package">
                <NewPricePackage />
              </Route>
              </Switch>
              <Switch>
              <Route path="/agreements/:agreementId">
                <AgreementUpdate />
              </Route>
              <Route path="/agreements">
                <AgreementList />
              </Route>
              <Route path="/new-agreement">
                <NewAgreement />
              </Route>
              </Switch>
            <Switch>
            <Route path="/industries/:industryId">
              <IndustryUpdate />
              </Route>
            <Route path="/industries">
                <IndustryList />
              </Route>
              <Route path="/new-industry">
                <NewIndustry />
              </Route>
            </Switch>
            <Switch>
            <Route path="/proposals/:proposalId" component={ProposalDetail} />
            <Route path="/proposals">
                <ProposalList />
              </Route>
            </Switch>
              <Switch>
                <Route path="/trash-can">
                <TrashCanList />
                </Route>
              </Switch>
            </div>
          </>
      </Switch>
    </Router>
  );
}

export default App;