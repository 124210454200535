import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import '../proposalList/proposallist.css';  // Reuse the same styles as ProposalList
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline, Restore } from "@material-ui/icons";
import { getDeletedProposals, permanentlyDeleteProposal, restoreProposal } from "../../../redux/apiCalls";  // You need to define these calls similar to getProposals
import {InfinitySpin } from 'react-loader-spinner';

const TrashCanList = () => {
  const dispatch = useDispatch();
  const proposalState = useSelector((state) => state.proposals);
  
  useEffect(() => {
    getDeletedProposals(dispatch);
  }, [dispatch]);

  if (!proposalState.proposals) {
    return <div className="userList">Proposals array is undefined</div>;
  }

  const { proposals, isFetching, error } = proposalState;

  const handlePermanentDelete = (id) => {
    permanentlyDeleteProposal(id, dispatch);
  };

  const handleRestore = (id) => {
    restoreProposal(id, dispatch);
  };

  const columns = [
    { field: "brandName", headerName: "Brand Name", width: 150, flex: 1 },
    {
        field: "industry",
        headerName: "Industry",
        width: 150,
        valueGetter: (params) => params.row.industry ? params.row.industry.name : "Not specified"
    },
    { field: "clientType", headerName: "Client Type", width: 120 },
    {
      field: "packageName",
      headerName: "Package Name",
      width: 160,
      valueGetter: (params) => params.row.selectedPackage ? params.row.selectedPackage.name : "Not specified"
  },
  {
    field: "signedDate",
    headerName: "Signed Date",
    width: 180,
    renderCell: (params) => {
      return params.row.signedDate
        ? new Date(params.row.signedDate).toLocaleDateString()
        : "Not signed";
    },
  },
    { field: "timeFrame", headerName: "Time Frame (Days)", width: 160 },

    { 
        field: "createdDate", 
        headerName: "Created Date", 
        width: 180, 
        renderCell: (params) => {
            const createdDate = new Date(params.row.createdDate);
            return createdDate.toLocaleDateString(); 
        }
    },
    {
        field: "action",
        headerName: "Action",
        width: 150,
        renderCell: (params) => {
            return (
                <>
                    <Restore
                        className="userListRestore"
                        onClick={() => handleRestore(params.row._id)}
                    />
                    <DeleteOutline
                        className="userListDelete"
                        onClick={() => handlePermanentDelete(params.row._id)}
                    />
                </>
            );
        },
    },
  ];

  if (isFetching) return <div className="loadingSpinner" >
    <InfinitySpin
         visible={true}
         width="200"
         color="#3949AB"
         ariaLabel="infinity-spin-loading"
       />
 </div>;
 if (error) return <div className="userList">Error fetching deleted proposals</div>;

 return (
   <div className="userList">
     <DataGrid
       rows={proposals}
       getRowId={(row) => row._id}
       columns={columns}
       pageSize={100}
     />
   </div>
 );
};

export default TrashCanList;
