import React, { useEffect, useState , useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateStrategy } from "../../redux/apiCalls";
import { Editor } from '@tinymce/tinymce-react';
import { FormGroup, FormControlLabel, Checkbox, TextField, Select, MenuItem, Button, FormControl, InputLabel, Box, Typography, Grid } from '@mui/material';
import axios from 'axios';
import "./card.css";

export default function StrategyUpdate() {
  const { strategyId } = useParams();
  const dispatch = useDispatch();
  const [strategy, setStrategy] = useState({
    content: '',
    strategyType: '',
    industries: [],
    pricingPackage: {_id: '', name: '', pricing: ''},
    youtubeVideo: '', // Include this field
    imageFile: null
  });
  const [industries, setIndustries] = useState([]);
  const [packages, setPackages] = useState([]);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    axios.get('https://backend.marketing.biz/api/industries').then(res => {
      setIndustries(res.data);
    }).catch(err => setError('Failed to fetch industries'));

    axios.get('https://backend.marketing.biz/api/pricing-packages').then(res => {
      setPackages(res.data);
    }).catch(err => setError('Failed to fetch packages'));

    axios.get(`https://backend.marketing.biz/api/strategies/${strategyId}`).then(res => {
      setStrategy(res.data);
    }).catch(err => {
      setError('Strategy not found');
    });
  }, [strategyId]);

  const handleIndustryChange = (industry) => {
    setStrategy(prev => {
      const isIndustrySelected = prev.industries.some(i => i._id === industry._id);
      if (isIndustrySelected) {
        return {
          ...prev,
          industries: prev.industries.filter(i => i._id !== industry._id)
        };
      } else {
        return {
          ...prev,
          industries: [...prev.industries, industry]
        };
      }
    });
  };

  const handlePackageChange = (event) => {
    const selectedPackage = packages.find(pkg => pkg._id === event.target.value);
    setStrategy(prev => ({
      ...prev,
      pricingPackage: selectedPackage
    }));
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (strategy) {
      const formData = new FormData();
      formData.append("content", strategy.content);
      formData.append("strategyType", strategy.strategyType);
      formData.append("industries", JSON.stringify(strategy.industries));
      formData.append("pricingPackage", JSON.stringify(strategy.pricingPackage)); 
      formData.append("youtubeVideo", strategy.youtubeVideo); 
  
      if (strategy.imageFile) {
        formData.append("image", strategy.imageFile);
      }
  
      try {
        await updateStrategy(strategyId, formData, dispatch);
        alert("Strategy updated successfully!");
      } catch (error) {
        setError('Failed to update strategy');
        console.error('Error updating strategy:', error);
      }
    }
  };
  
  
  const handleFileChange = (e) => {
    setStrategy({ ...strategy, imageFile: e.target.files[0] });
  };
  

  const handleEditorChange = (content) => {
    setStrategy({ ...strategy, content });
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!strategy) {
    return <div>Loading...</div>;
  }

  return (
    <Box className="strategyUpdate" >
      <Typography variant="h4" gutterBottom>Update Strategy</Typography>
      <Link to="/newStrategy" style={{ textDecoration: 'none' }}>
        <Button variant="contained" color="primary">Create New</Button>
      </Link>
      <form onSubmit={handleUpdate}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Editor
              apiKey="3f1a54mgnuot0j04sqjkrk3ett5tsgzr9sblkjixayw6ob6o"
              value={strategy.content}
              onEditorChange={handleEditorChange}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
            }}
            />
          </Grid>
          <Grid item xs={12}>
          <div className="file-upload">
            <Button variant="contained" onClick={() => fileInputRef.current.click()}>Upload Image</Button>
            <input
  type="file"
  ref={fileInputRef}
  onChange={handleFileChange}
  style={{ display: 'none' }}
/>

          </div>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Strategy Type</InputLabel>
              <Select
                label="Strategy Type"
                value={strategy.strategyType}
                onChange={(e) => setStrategy({ ...strategy, strategyType: e.target.value })}
              >
                
                <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="B2B">B2B</MenuItem>
              <MenuItem value="B2C">B2C</MenuItem>
              <MenuItem value="All">All</MenuItem> 
                
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography>Select Industries</Typography>
            <FormGroup>
              {industries.map(industry => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={strategy.industries.some(i => i._id === industry._id)}
                      onChange={() => handleIndustryChange(industry)}
                    />
                  }
                  label={industry.name}
                  key={industry._id}
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Pricing Package</InputLabel>
              <Select
                value={strategy.pricingPackage?._id || ''}
                label="Pricing Package"
                onChange={handlePackageChange}
              >
                {packages.map(pkg => (
                  <MenuItem key={pkg._id} value={pkg._id}>{pkg.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <TextField
                name="youtubeVideo"
                label="YouTube Video URL"
                value={strategy.youtubeVideo}
                onChange={(e) => setStrategy({ ...strategy, youtubeVideo: e.target.value })}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Update Strategy
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
