import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./agreement.css";
import { updateAgreement } from "../../../redux/apiCalls";
import { Editor } from '@tinymce/tinymce-react';

export default function AgreementUpdate() {
  const { agreementId } = useParams();
  const dispatch = useDispatch();
  const agreements = useSelector((state) => state.agreement.agreements);
  console.log(agreements);
  const [agreement, setAgreement] = useState({
    content: '',
    pricing: '',
    packageDetails: '',
    termsAndConditions: ''
  });

  useEffect(() => {
    const foundAgreement = agreements.find(ag => ag._id === agreementId);
    if (foundAgreement) {
      setAgreement(foundAgreement);
    }
  }, [agreementId, agreements]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    await updateAgreement(agreementId, agreement, dispatch);
    alert("Agreement updated successfully!");
  };

  const handleEditorChange = (content, editor) => {
    setAgreement({ ...agreement, content });
  };

  const handleTermsAndConditionChange = (termsAndConditions, editor) => {
    setAgreement({ ...agreement, termsAndConditions });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAgreement({ ...agreement, [name]: value });
  };

  if (!agreement) {
    return <div>Loading...</div>;
  }

  return (
    <div className="agreement">
      <div className="agreementTitleContainer">
        <h1 className="agreementTitle">Update Agreement</h1>
        <Link to="/new-agreement">
          <button className="agreementAddButton">Create New</button>
        </Link>
      </div>
      <div className="agreementBottom">
        <form className="agreementForm" onSubmit={handleUpdate}>
          <div className="agreementFormLeft">
            <label>Content</label>
            <Editor
                            apiKey='3f1a54mgnuot0j04sqjkrk3ett5tsgzr9sblkjixayw6ob6o'
                            value={agreement.content}
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                            }}
                            onEditorChange={handleEditorChange}
                        />
                   
            <label>Pricing</label>
            <input
              type="number"
              name="pricing"
              defaultValue={agreement.pricing}
              className="formInput"
              onChange={handleInputChange}
            />
           <label>Package Details</label>
            <textarea
              name="packageDetails"
              defaultValue={agreement.packageDetails}
              className="formInput"
              onChange={handleInputChange}
            />
            <div className="formInput">
                    <label>Terms & Conditions</label>
                    <Editor
                            apiKey='3f1a54mgnuot0j04sqjkrk3ett5tsgzr9sblkjixayw6ob6o'
                            value={agreement.termsAndConditions}
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                            }}
                            onEditorChange={handleTermsAndConditionChange}
                        />
                    </div>
                   
          </div>
          
                            
          <div className="agreementFormRight">
            <button type="submit" className="agreementButton">Update</button>
          </div>
          
        </form>
      </div>
    </div>
  );
}
