import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addAgreement } from '../../../redux/apiCalls';
import { Editor } from '@tinymce/tinymce-react';
import { TextField, Button, Box, Typography, Grid } from '@mui/material';
import './newagreement.css';

export default function NewAgreement() {
    const dispatch = useDispatch();
    
    const initialFormData = {
        content: '',
        pricing: '',
        packageDetails: '',
        termsAndConditions: ''
    };

    const [agreementData, setAgreementData] = useState(initialFormData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAgreementData({ ...agreementData, [name]: value });
    };

    const handleEditorChange = (content, editor) => {
        setAgreementData({ ...agreementData, content: content });
    };
    const handleTermsAndConditionChange = (termsAndConditions, editor) => {
        setAgreementData({ ...agreementData, termsAndConditions: termsAndConditions });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await dispatch(addAgreement(agreementData));
            alert("Agreement created successfully!");
            setAgreementData(initialFormData); // Reset form data
        } catch (error) {
            alert("Failed to create agreement: " + error.message);
        }
    };

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom>Create Agreement</Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Content</Typography>
                        <Editor
                            apiKey='3f1a54mgnuot0j04sqjkrk3ett5tsgzr9sblkjixayw6ob6o'
                            value={agreementData.content}
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                            }}
                            onEditorChange={handleEditorChange}
                        />
                        <div className="">
                    <label>Terms & Conditions</label>
                    <Editor
                            apiKey='3f1a54mgnuot0j04sqjkrk3ett5tsgzr9sblkjixayw6ob6o'
                            value={agreementData.termsAndConditions}
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                            }}
                            onEditorChange={handleTermsAndConditionChange}
                        />
                    </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            type="number"
                            label="Pricing"
                            name="pricing"
                            value={agreementData.pricing}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Package Details"
                            name="packageDetails"
                            value={agreementData.packageDetails}
                            onChange={handleChange}
                            variant="outlined"
                            multiline
                            rows={4}
                        />
                         
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Create Agreement
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}
