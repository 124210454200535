import React, { useState } from "react";
import "./sidebar.css";
import { Business, Description, Assignment, MonetizationOn, CreditCard, DeleteTwoTone } from "@material-ui/icons";
import { Link } from "react-router-dom";

export default function Sidebar() {
  const [activeLink, setActiveLink] = useState(""); // State to track active link

  // Function to set active link
  const handleSetActiveLink = (link) => { // Debugging statement
    setActiveLink(link); // Update the activeLink state with the clicked link
  };


  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <ul className="sidebarList">
          <Link to="/proposals" className="link" onClick={() => handleSetActiveLink("/proposals")}>
  <li className={`sidebarListItem ${activeLink === "/proposals" ? "active" : ""}`}>
    <Description className="sidebarIcon" />
   <p className="paragraphText">Proposals</p> 
  </li>
      </Link>
            <Link
              to="/strategies"
              className="link" onClick={() => handleSetActiveLink("/strategies")}
            >
              <li  className={`sidebarListItem ${activeLink === "/strategies" ? "active" : ""}`} >
                <CreditCard className="sidebarIcon" />
             <p className="paragraphText">Strategy</p>  
              </li>
            </Link>
            <Link
              to="/pricing-packages"
              className="link" onClick={() => handleSetActiveLink("/pricing-packages")}
            >
              <li className={`sidebarListItem ${activeLink === "/pricing-packages" ? "active" : ""}`}>
                <MonetizationOn className="sidebarIcon" />
               <p className="paragraphText">Pricing Packages</p> 
              </li>
            </Link>
            <Link
              to="/agreements"
              className="link" onClick={() => handleSetActiveLink("/agreements")}
            >
              <li className={`sidebarListItem ${activeLink === "/agreements" ? "active" : ""}`}>
                <Assignment className="sidebarIcon" />
                <p className="paragraphText">Agreements</p>
              </li>
            </Link>
            <Link
              to="/industries"
              className="link" onClick={() => handleSetActiveLink("/industries")}
            >
              <li className={`sidebarListItem ${activeLink === "/industries" ? "active" : ""}`}>
                <Business className="sidebarIcon" />
              <p className="paragraphText">Industries</p>  
              </li>
            </Link>
            <Link
              to="/trash-can"
              className="link" onClick={() => handleSetActiveLink("/trash-can")}
            >
              <li className={`sidebarListItem ${activeLink === "/trash-can" ? "active" : ""}`}>
                <DeleteTwoTone className="sidebarIcon" />
               <p className="paragraphText">Trash</p>
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
}