import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./industry.css"; // Use similar CSS to agreement.css or adjust as necessary
import { updateIndustry } from "../../../redux/apiCalls"; // Adjust the import path as necessary

export default function IndustryUpdate() {
  const { industryId } = useParams();
  const dispatch = useDispatch();
  const industries = useSelector((state) => state.industry.industries);
  const [industry, setIndustry] = useState({ name: '', visibleTo: 'All' });

  useEffect(() => {
    const foundIndustry = industries.find((ind) => ind._id === industryId);
    if (foundIndustry) {
      setIndustry(foundIndustry);
    }
  }, [industryId, industries]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    await updateIndustry(industryId, industry, dispatch);
    alert("Industry updated successfully!");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIndustry(prev => ({ ...prev, [name]: value }));
  };

  if (!industry) {
    return <div>Loading...</div>;
  }

  return (
    <div className="industry">
      <div className="industryTitleContainer">
        <h1 className="industryTitle">Update Industry</h1>
        <Link to="/new-industry">
          <button className="industryAddButton">Create New</button>
        </Link>
      </div>
      <div className="industryBottom">
        <form className="industryForm" onSubmit={handleUpdate}>
          <div className="industryFormLeft">
            <label>Name</label>
            <input
              type="text"
              name="name"
              value={industry.name}
              onChange={handleChange}
              className="formInput"
            />
            <fieldset>
              <legend>Visible To</legend>
              <label>
                <input
                  type="radio"
                  name="visibleTo"
                  value="All"
                  checked={industry.visibleTo === "All"}
                  onChange={handleChange}
                /> All
              </label>
              <label>
                <input
                  type="radio"
                  name="visibleTo"
                  value="B2B"
                  checked={industry.visibleTo === "B2B"}
                  onChange={handleChange}
                /> B2B
              </label>
              <label>
                <input
                  type="radio"
                  name="visibleTo"
                  value="B2C"
                  checked={industry.visibleTo === "B2C"}
                  onChange={handleChange}
                /> B2C
              </label>
            </fieldset>
          </div>
          <div className="industryFormRight">
            <button type="submit" className="industryButton">Update</button>
          </div>
        </form>
      </div>
    </div>
  );
}
