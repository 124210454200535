// strategyRedux.js
import { createSlice } from '@reduxjs/toolkit';

export const strategySlice = createSlice({
  name: 'strategy',
  initialState: {
    strategies: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    // Fetching strategies
    getStrategiesStart: state => {
      state.isFetching = true;
      state.error = false;
    },
    getStrategiesSuccess: (state, action) => {
      state.isFetching = false;
      state.strategies = action.payload;
      state.error = false;  
    },
    getStrategiesFailure: state => {
      state.isFetching = false;
      state.error = true;
    },
    // Adding strategy
    addStrategyStart: state => {
      state.isFetching = true;
      state.error = false;
    },
    addStrategySuccess: (state, action) => {
      state.isFetching = false;
      state.strategies.push(action.payload);
    },
    addStrategyFailure: state => {
      state.isFetching = false;
      state.error = true;
    },
    // Updating strategy
    updateStrategyStart: state => {
      state.isFetching = true;
      state.error = false;
    },
    updateStrategySuccess: (state, action) => {
      state.isFetching = false;
      const index = state.strategies.findIndex(strategy => strategy._id === action.payload._id);
      if (index !== -1) {
        state.strategies[index] = action.payload;
      }
    },
    updateStrategyFailure: state => {
      state.isFetching = false;
      state.error = true;
    },
    // Deleting strategy
    deleteStrategyStart: state => {
      state.isFetching = true;
      state.error = false;
    },
    deleteStrategySuccess: (state, action) => {
      state.isFetching = false;
      state.strategies = state.strategies.filter(strategy => strategy._id !== action.payload);
    },
    deleteStrategyFailure: state => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getStrategiesStart,
  getStrategiesSuccess,
  getStrategiesFailure,
  addStrategyStart,
  addStrategySuccess,
  addStrategyFailure,
  updateStrategyStart,
  updateStrategySuccess,
  updateStrategyFailure,
  deleteStrategyStart,
  deleteStrategySuccess,
  deleteStrategyFailure,
} = strategySlice.actions;

export default strategySlice.reducer;
