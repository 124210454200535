import { configureStore, combineReducers } from "@reduxjs/toolkit";
import productReducer from "./productRedux";
import strategyReducer from "./strategyRedux";
import pricingPackageReducer from "./pricePackageRedux";
import agreementReducer from "./agreementRedux";
import industryReducer from "./industryRedux";
import proposalReducer from './proposalRedux';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1, 
  storage,
};

const rootReducer = combineReducers({
  product: productReducer,
  pricingPackage: pricingPackageReducer,
  agreement: agreementReducer,
  industry: industryReducer,
  proposals: proposalReducer,
  strategy: strategyReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store, null, () => {
  if (Object.keys(store.getState()).length === 0) {
    console.warn("Persisted state is empty, initializing store with defaults or fetching from server if applicable.");
   
  }
});
