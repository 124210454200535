import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updatePricingPackage } from "../../redux/apiCalls";
import { Button, TextField, Card, CardContent, Typography, Grid, Box, Container } from "@mui/material";

export default function ProductPackages() {
  const { pricingPackageId } = useParams();
  const dispatch = useDispatch();
  const [pricingPackage, setPricingPackage] = useState(null);
  const [features, setFeatures] = useState([]);

  const pricingPackages = useSelector(state => state.pricingPackage.packages);

  useEffect(() => {
    const foundPricingPackage = pricingPackages.find(pkg => pkg._id === pricingPackageId);
    if (foundPricingPackage) {
      setPricingPackage({
        ...foundPricingPackage,
        webhookUrl: foundPricingPackage.webhookUrl || ''  // Initialize webhookUrl if not present
      });
      setFeatures(foundPricingPackage.features || []);
    }
  }, [pricingPackageId, pricingPackages]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const updatedPricingPackage = {
        name: e.target.name.value,
        features,
        pricing: parseFloat(e.target.pricing.value), // Handle pricing as a float
        stripeSetupLink: e.target.stripeSetupLink.value,
        webhookUrl: e.target.webhookUrl.value  // Include webhookUrl in the update
      };
      await updatePricingPackage(pricingPackageId, updatedPricingPackage, dispatch);
      alert("Pricing Package updated successfully!");
    } catch (error) {
      console.error("Error updating pricing package:", error);
    }
  };

  const handleFeatureChange = (index, value) => {
    const updatedFeatures = [...features];
    updatedFeatures[index] = value;
    setFeatures(updatedFeatures);
  };

  if (!pricingPackage) {
    return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Typography>Loading...</Typography>
    </Box>;
  }

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Pricing Package
        </Typography>
        <Button
          variant="contained"
          component={Link}
          to="/newprice-package"
          sx={{ mb: 2 }}
        >
          Create New
        </Button>
      </Box>
      <form onSubmit={handleUpdate}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Webhook URL"
                  name="webhookUrl"
                  defaultValue={pricingPackage.webhookUrl}
                  variant="outlined"
                  placeholder="Enter webhook URL"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
             
                <TextField
                  fullWidth
                  label="Package Name"
                  name="name"
                  defaultValue={pricingPackage.name}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Stripe Setup Link"
                  name="stripeSetupLink"
                  defaultValue={pricingPackage.stripeSetupLink}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Pricing"
                  name="pricing"
                  type="number"
                  defaultValue={pricingPackage.pricing}
                  variant="outlined"
                  required
                />
              </Grid>
             
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Features
                </Typography>
                {features.map((feature, index) => (
                  <TextField
                    key={index}
                    fullWidth
                    label={`Feature ${index + 1}`}
                    value={feature}
                    onChange={(e) => handleFeatureChange(index, e.target.value)}
                    variant="outlined"
                    sx={{ mb: 1 }}
                    required
                  />
                ))}
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </Container>
  );
}
