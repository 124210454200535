// IndustryList.js
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import './industrylist.css';  // Use the same CSS as for the AgreementList
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { getIndustries, deleteIndustry } from "../../../redux/apiCalls";  // Adjust the path as necessary
import {InfinitySpin } from 'react-loader-spinner';
const IndustryList = () => {
  const dispatch = useDispatch();
  const { industries, isFetching, error } = useSelector((state) => state.industry);
  console.log(industries);
  useEffect(() => {
    getIndustries(dispatch);
  }, [dispatch]);

  const handleDelete = (id) => {
    if(window.confirm('Are you sure you want to delete this industry?')) {
      deleteIndustry(id, dispatch);
    }
  };

  const columns = [
    { field: "name", headerName: "Name", width: 200, flex: 1 },
    { field: "visibleTo", headerName: "Visible To", width: 150 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/industries/" + params.row._id}>
              <button className="userListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="userListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
  ];

  if (isFetching) return <div className="loadingSpinner" >
     <InfinitySpin
          visible={true}
          width="200"
          color="#3949AB"
          ariaLabel="infinity-spin-loading"
        />
  </div>;
  if (error) return <div className="userList">Error fetching industries</div>;

  return (
    <div className="userList">
      <div className="createButton">
      <Link to="/new-industry">
          <button className="industryAddButton">Create New</button>
        </Link>
    
      </div>
      <DataGrid
        rows={industries}
        getRowId={(row) => row._id}
        disableSelectionOnClick
        columns={columns}
        pageSize={8}
        checkboxSelection
      />
    </div>
  );
};

export default IndustryList;
