import { createSlice } from "@reduxjs/toolkit";

export const pricingPackageSlice = createSlice({
  name: "pricingPackage",
  initialState: {
    packages: [],
    isFetching: false,
    error: false,
  },
  reducers: {
  
    getPricingPackagesStart: state => {
      state.isFetching = true;
      state.error = false;
    },
    getPricingPackagesSuccess: (state, action) => {
      state.isFetching = false;
      state.packages = action.payload;
      state.error = false;  
    },
    getPricingPackagesFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    // DELETE PACKAGE
    deletePricingPackageStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deletePricingPackageSuccess: (state, action) => {
      state.isFetching = false;
      state.packages = state.packages.filter((pkg) => pkg._id !== action.payload);
    },
    deletePricingPackageFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    // UPDATE PACKAGE
    updatePricingPackageStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updatePricingPackageSuccess: (state, action) => {
      state.isFetching = false;
      const index = state.packages.findIndex((pkg) => pkg._id === action.payload.id);
      state.packages[index] = { ...state.packages[index], ...action.payload.package };
    },
    updatePricingPackageFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    // ADD PACKAGE
    addPricingPackageStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addPricingPackageSuccess: (state, action) => {
      state.isFetching = false;
      state.packages.push(action.payload);
    },
    addPricingPackageFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getPricingPackagesStart,
  getPricingPackagesSuccess,
  getPricingPackagesFailure,
  deletePricingPackageStart,
  deletePricingPackageSuccess,
  deletePricingPackageFailure,
  updatePricingPackageStart,
  updatePricingPackageSuccess,
  updatePricingPackageFailure,
  addPricingPackageStart,
  addPricingPackageSuccess,
  addPricingPackageFailure,
} = pricingPackageSlice.actions;

export default pricingPackageSlice.reducer;
