import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "./proposallist.css"; // Assuming you're reusing the same styles from the industry list
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { getProposals, softDeleteProposal } from "../../../redux/apiCalls"; // Adjust path as necessary
import { InfinitySpin } from "react-loader-spinner";
import DeleteModal from "../../../components/DeleteModal/index"; // Adjust the path if necessary

const ProposalList = () => {
  const dispatch = useDispatch();
  const proposalState = useSelector((state) => state.proposals);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [search, setSearch] = useState("");


  useEffect(() => {
    getProposals(dispatch);
  }, [dispatch]);

  if (!proposalState.proposals) {
    return <div className="userList">Proposals array is undefined</div>;
  }

  const { proposals, isFetching, error } = proposalState;

  const handleDelete = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
    setSelectedId(null);
  };

  const handleConfirm = () => {
    softDeleteProposal(selectedId, dispatch);
    setShowModal(false);
    setSelectedId(null);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  
   // Ensure proposals is available before filtering
   const filteredProposals = proposalState.proposals ? proposalState.proposals.filter((proposal) =>
   proposal.brandName.toLowerCase().includes(search.toLowerCase())
 ) : [];
  const columns = [
    { field: "brandName", headerName: "Brand Name", width: 200, flex: 1 },
    {
      field: "industry",
      headerName: "Industry",
      width: 150,
      valueGetter: (params) =>
        params.row.industry ? params.row.industry.name : "Not specified",
    },
    { field: "clientType", headerName: "Client Type", width: 120 },
    {
      field: "packageName",
      headerName: "Package Name",
      width: 160,
      valueGetter: (params) =>
        params.row.selectedPackage
          ? params.row.selectedPackage.name
          : "Not specified",
    },
    { field: "timeFrame", headerName: "Time Frame (Days)", width: 160 },
    {
      field: "signedDate",
      headerName: "Signed Date",
      width: 180,
      renderCell: (params) => {
        return params.row.signedDate
          ? new Date(params.row.signedDate).toLocaleDateString()
          : "Not signed";
      },
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 180,
      renderCell: (params) => {
        const createdDate = new Date(params.row.createdDate);
        return createdDate.toLocaleDateString();
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/proposals/" + params.row._id}>
              <button className="userListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="userListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
  ];

  if (isFetching)
    return (
      <div className="loadingSpinner">
        <InfinitySpin
          visible={true}
          width="200"
          color="#3949AB"
          ariaLabel="infinity-spin-loading"
        />
      </div>
    );
  if (error) return <div className="userList">Error fetching proposals</div>;

  return (
    <div className="userList">
      {showModal && <DeleteModal onCancel={handleCancel} onConfirm={handleConfirm} />}
      <div className="search">
      <input
        type="text"
        value={search}
        onChange={handleSearchChange}
        placeholder="Search by Brand Name"
        className="searchInput"
      />
      </div>
     
      <DataGrid
        rows={filteredProposals}
        getRowId={(row) => row._id}
        columns={columns}
        pageSize={99}
      />
    </div>
  );
};

export default ProposalList;
