// proposalRedux.js
import { createSlice } from '@reduxjs/toolkit';

export const proposalSlice = createSlice({
  name: 'proposal',
  initialState: {
    proposals: [],
    proposal: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    fetchProposalsStart: state => {
      state.isFetching = true;
      state.error = false;
    },
    fetchProposalsSuccess: (state, action) => {
      state.isFetching = false;
      state.error = false;
      state.proposals = action.payload; // Ensure proposals are updated with action payload
    },
    
    fetchProposalsFailure: state => {
      state.isFetching = false;
      state.error = true;
    },
    addProposalStart: state => {
      state.isFetching = true;
      state.error = false;
    },
    addProposalSuccess: (state, action) => {
      state.isFetching = false;
      state.proposals.push(action.payload);
    },
    addProposalFailure: state => {
      state.isFetching = false;
      state.error = true;
    },
    updateProposalStart: state => {
      state.isFetching = true;
      state.error = false;
    },
    updateProposalSuccess: (state, action) => {
      state.isFetching = false;
      const index = state.proposals.findIndex(proposal => proposal._id === action.payload._id);
      if (index !== -1) {
        state.proposals[index] = action.payload;
      }
    },
    updateProposalFailure: state => {
      state.isFetching = false;
      state.error = true;
    },
    deleteProposalStart: state => {
      state.isFetching = true;
      state.error = false;
    },
    deleteProposalSuccess: (state, action) => {
      state.isFetching = false;
      // Filter out the soft-deleted proposal from the proposal list
      state.proposals = state.proposals.filter(proposal => proposal._id !== action.payload);
    },
    
    deleteProposalFailure: state => {
      state.isFetching = false;
      state.error = true;
    },
    restoreProposalStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    restoreProposalSuccess: (state, action) => {
      state.isFetching = false;
      const index = state.proposals.findIndex(proposal => proposal._id === action.payload._id);
      if (index !== -1) {
        state.proposals[index] = action.payload;
      }
    },
    restoreProposalFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    fetchProposalStart: state => {
      state.isFetching = true;
      state.error = false;
    },
    fetchProposalSuccess: (state, action) => {
      state.isFetching = false;
      state.error = false;
      state.proposal = action.payload; // Make sure this is set for a single proposal
    },
    fetchProposalFailure: state => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  fetchProposalsStart,
  fetchProposalsSuccess,
  fetchProposalsFailure,
  addProposalStart,
  addProposalSuccess,
  addProposalFailure,
  updateProposalStart,
  updateProposalSuccess,
  updateProposalFailure,
  deleteProposalStart,
  deleteProposalSuccess,
  deleteProposalFailure,
  restoreProposalStart,
  restoreProposalSuccess,
  restoreProposalFailure,
  fetchProposalStart,
  fetchProposalSuccess,
  fetchProposalFailure


} = proposalSlice.actions;

export default proposalSlice.reducer;
