import { createSlice } from "@reduxjs/toolkit";

export const agreementSlice = createSlice({
  name: "agreement",
  initialState: {
    agreements: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    // GET ALL AGREEMENTS
    getAgreementsStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getAgreementsSuccess: (state, action) => {
      state.isFetching = false;
      state.agreements = action.payload;
    },
    getAgreementsFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    // DELETE AGREEMENT
    deleteAgreementStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteAgreementSuccess: (state, action) => {
      state.isFetching = false;
      state.agreements = state.agreements.filter((agreement) => agreement._id !== action.payload);
    },
    deleteAgreementFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    // UPDATE AGREEMENT
    updateAgreementStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateAgreementSuccess: (state, action) => {
      state.isFetching = false;
      const index = state.agreements.findIndex((agreement) => agreement._id === action.payload.id);
      state.agreements[index] = { ...state.agreements[index], ...action.payload.agreement };
    },
    updateAgreementFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    // ADD AGREEMENT
    addAgreementStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addAgreementSuccess: (state, action) => {
      state.isFetching = false;
      state.agreements.push(action.payload);
    },
    addAgreementFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getAgreementsStart,
  getAgreementsSuccess,
  getAgreementsFailure,
  deleteAgreementStart,
  deleteAgreementSuccess,
  deleteAgreementFailure,
  updateAgreementStart,
  updateAgreementSuccess,
  updateAgreementFailure,
  addAgreementStart,
  addAgreementSuccess,
  addAgreementFailure,
} = agreementSlice.actions;

export default agreementSlice.reducer;
