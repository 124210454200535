import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "./cardlist.css"; // Adjust the CSS path as needed
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { InfinitySpin } from 'react-loader-spinner';
import { htmlToText } from 'html-to-text';
import {
  getStrategies, // Make sure this is correctly imported from your apiCalls file
  deleteStrategy, // Make sure this is correctly imported from your apiCalls file
} from "../../redux/apiCalls"; // Adjust the import path as necessary

export default function StrategyList() {
  const dispatch = useDispatch();
  const { strategies, isFetching, error } = useSelector((state) => state.strategy);

  useEffect(() => {
    getStrategies(dispatch);
  }, [dispatch]);

  const handleDelete = (id) => {
    deleteStrategy(id, dispatch);
  };

  const columns = [
    {
      field: "content",
      headerName: "Content",
      width: 200,
      flex: 1,
      renderCell: (params) => {
        const plainTextContent = htmlToText(params.value, {
          wordwrap: 130
        });
        return (
          <div>{plainTextContent}</div>
        );
      },
    },
    { field: "strategyType", headerName: "Type", width: 120 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/strategies/" + params.row._id}>
              <button className="userListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="userListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
  ];

  if (isFetching) return (
    <div className="loadingSpinner">
      <InfinitySpin
        visible={true}
        width="200"
        color="#3949AB"
        ariaLabel="infinity-spin-loading"
      />
    </div>
  );

  if (error) return <div>Error fetching strategies</div>;

  return (
    <div className="userList">
      <div className="createButton">
        <Link to="/newStrategy">
          <button className="agreementAddButton">New Strategy</button>
        </Link>
      </div>
      <DataGrid
        rows={strategies}
        getRowId={(row) => row._id}
        disableSelectionOnClick
        columns={columns}
        pageSize={8}
        checkboxSelection
      />
    </div>
  );
}