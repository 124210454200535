// industryRedux.js
import { createSlice } from '@reduxjs/toolkit';

export const industrySlice = createSlice({
  name: 'industry',
  initialState: {
    industries: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    // Fetching industries
    getIndustriesStart: state => {
      state.isFetching = true;
      state.error = false;
    },
    getIndustriesSuccess: (state, action) => {
      state.isFetching = false;
      state.industries = action.payload;
      state.error = false;  
    },
    getIndustriesFailure: state => {
      state.isFetching = false;
      state.error = true;
    },
    // Adding industry
    addIndustryStart: state => {
      state.isFetching = true;
      state.error = false;
    },
    addIndustrySuccess: (state, action) => {
      state.isFetching = false;
      state.industries.push(action.payload);
    },
    addIndustryFailure: state => {
      state.isFetching = false;
      state.error = true;
    },
    // Updating industry
    updateIndustryStart: state => {
      state.isFetching = true;
      state.error = false;
    },
    updateIndustrySuccess: (state, action) => {
      state.isFetching = false;
      const index = state.industries.findIndex(industry => industry._id === action.payload._id);
      if (index !== -1) {
        state.industries[index] = action.payload;
      }
    },
    updateIndustryFailure: state => {
      state.isFetching = false;
      state.error = true;
    },
    // Deleting industry
    deleteIndustryStart: state => {
      state.isFetching = true;
      state.error = false;
    },
    deleteIndustrySuccess: (state, action) => {
      state.isFetching = false;
      state.industries = state.industries.filter(industry => industry._id !== action.payload);
    },
    deleteIndustryFailure: state => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getIndustriesStart,
  getIndustriesSuccess,
  getIndustriesFailure,
  addIndustryStart,
  addIndustrySuccess,
  addIndustryFailure,
  updateIndustryStart,
  updateIndustrySuccess,
  updateIndustryFailure,
  deleteIndustryStart,
  deleteIndustrySuccess,
  deleteIndustryFailure,
} = industrySlice.actions;

export default industrySlice.reducer;
