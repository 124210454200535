import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addIndustry } from '../../../redux/apiCalls';
import { Button, TextField, Card, CardContent, Typography, Select, MenuItem, FormControl, InputLabel, Box, IconButton, Container } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export default function NewIndustry() {
    const dispatch = useDispatch();
    
    const initialFormData = {
        names: [''],
        visibleTo: 'All'
    };

    const [industryData, setIndustryData] = useState(initialFormData);

    const handleNameChange = (index, value) => {
        const newNames = [...industryData.names];
        newNames[index] = value;
        setIndustryData({ ...industryData, names: newNames });
    };

    const handleAddNameField = () => {
        setIndustryData(prev => ({ ...prev, names: [...prev.names, ''] }));
    };

    const handleRemoveNameField = index => {
        if (industryData.names.length > 1) {
            const newNames = industryData.names.filter((_, idx) => idx !== index);
            setIndustryData(prev => ({ ...prev, names: newNames }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setIndustryData({ ...industryData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            const industryName = industryData.names[0]; 
            if (!industryName) {
                window.alert("Please enter at least one industry name.");
                return;
            }
            const data = { name: industryName, visibleTo: industryData.visibleTo };
            dispatch(addIndustry(data));
            window.alert("Industry created successfully!");
            setIndustryData(initialFormData); 
        } catch (error) {
            window.alert("Failed to create industry: " + error.message);
        }
    };

    return (
       
            <Container className="newUser">           
        <Box >
            <Card>
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom>
                        Create New Industry
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        {industryData.names.map((name, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <TextField
                                    fullWidth
                                    label={`Industry Name ${index + 1}`}
                                    variant="outlined"
                                    value={name}
                                    onChange={(e) => handleNameChange(index, e.target.value)}
                                    placeholder="Enter industry name"
                                />
                                {industryData.names.length > 1 && (
                                    <IconButton onClick={() => handleRemoveNameField(index)} color="error">
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </Box>
                        ))}
                        <Button startIcon={<AddCircleIcon />} onClick={handleAddNameField} sx={{ mb: 2 }}>
                            Add Another Name
                        </Button>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Visible To</InputLabel>
                            <Select
                                name="visibleTo"
                                value={industryData.visibleTo}
                                label="Visible To"
                                onChange={handleChange}
                            >
                                <MenuItem value="All">All</MenuItem>
                                <MenuItem value="B2B">B2B</MenuItem>
                                <MenuItem value="B2C">B2C</MenuItem>
                            </Select>
                        </FormControl>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Create Industry
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </Box>
        </Container>
    );
}
