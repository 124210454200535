import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import './agreementlist.css';
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import {
  getAgreements,
  deleteAgreement,
} from "../../../redux/apiCalls"; 
import { htmlToText } from 'html-to-text';
import {InfinitySpin } from 'react-loader-spinner';


const AgreementList = () => {
  const dispatch = useDispatch();
  const { agreements, isFetching, error } = useSelector((state) => state.agreement);

  useEffect(() => {
    getAgreements(dispatch);
  }, [dispatch]);

  const handleDelete = (id) => {
    if(window.confirm('Are you sure you want to delete this agreement?')) {
      deleteAgreement(id, dispatch);
    }
  };

  const columns = [
    {
      field: "content",
      headerName: "Content",
      width: 200,
      flex: 1,
      renderCell: (params) => {
        const plainTextContent = htmlToText(params.value, {
          wordwrap: 130
        });
        return (
          <div>{plainTextContent}</div>
        );
      },
    },
    // { field: "pricing", headerName: "Pricing", width: 100 },
    // { field: "packageDetails", headerName: "Package Details", width: 200, flex: 1 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/agreements/" + params.row._id}>
              <button className="userListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="userListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
  ];

  if (isFetching) return <div className="loadingSpinner" >
     <InfinitySpin
          visible={true}
          width="200"
          color="#3949AB"
          ariaLabel="infinity-spin-loading"
        />
  </div>;
  if (error) return <div>Error fetching agreements</div>;

  return (
    <div className="userList">
      <div className="createButton">
      <Link to="/new-agreement">
          <button className="agreementAddButton">Create New</button>
        </Link>
      </div>
      <DataGrid
        rows={agreements}
        getRowId={(row) => row._id}
        disableSelectionOnClick
        columns={columns}
        pageSize={8}
        checkboxSelection
      />
    </div>
  );
};

export default AgreementList;
