import React from "react";
import "./deletemodal.css"; // Assuming you save the CSS you provided as clearModal.css

const DeleteModal = ({ onCancel, onConfirm }) => {
  return (
    <div className="clear-modal-overlay">
    <div className="clear-modal">
      <div className="clear-modal-header">
        <h4>Delete This Proposal?</h4>
      </div>
      <div className="clear-modal-body">
        <p>Are you sure you want to delete this proposal?</p>
      </div>
      <div className="clear-modal-footer">
        <button className="btn btn-cancel" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-confirm" onClick={onConfirm}>
          Confirm
        </button>
      </div>
    </div>
  </div>
  );
};

export default DeleteModal;
