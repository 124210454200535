import { publicRequest, userRequest } from "../requestMethods";
import {
  getPricingPackagesStart,
  getPricingPackagesSuccess,
  getPricingPackagesFailure,
  deletePricingPackageStart,
  deletePricingPackageSuccess,
  deletePricingPackageFailure,
  updatePricingPackageStart,
  updatePricingPackageSuccess,
  updatePricingPackageFailure,
  addPricingPackageStart,
  addPricingPackageSuccess,
  addPricingPackageFailure,
} from './pricePackageRedux';
import {
  getAgreementsFailure,
  getAgreementsStart,
  getAgreementsSuccess,
  deleteAgreementFailure,
  deleteAgreementStart,
  deleteAgreementSuccess,
  updateAgreementFailure,
  updateAgreementStart,
  updateAgreementSuccess,
  addAgreementFailure,
  addAgreementStart,
  addAgreementSuccess,
} from './agreementRedux';
import {
  getIndustriesStart,
  getIndustriesSuccess,
  getIndustriesFailure,
  addIndustryStart,
  addIndustrySuccess,
  addIndustryFailure,
  updateIndustryStart,
  updateIndustrySuccess,
  updateIndustryFailure,
  deleteIndustryStart,
  deleteIndustrySuccess,
  deleteIndustryFailure,
} from './industryRedux';
import {
  fetchProposalsStart,
  fetchProposalsSuccess,
  fetchProposalsFailure,
  addProposalStart,
  addProposalSuccess,
  addProposalFailure,
  updateProposalStart,
  updateProposalSuccess,
  updateProposalFailure,
  deleteProposalStart,
  deleteProposalSuccess,
  deleteProposalFailure,
  restoreProposalStart,
  restoreProposalSuccess,
  restoreProposalFailure,
  fetchProposalFailure,
  fetchProposalSuccess,
  fetchProposalStart

} from './proposalRedux';
import {
  getStrategiesStart,
  getStrategiesSuccess,
  getStrategiesFailure,
  addStrategyStart,
  addStrategySuccess,
  addStrategyFailure,
  updateStrategyStart,
  updateStrategySuccess,
  updateStrategyFailure,
  deleteStrategyStart,
  deleteStrategySuccess,
  deleteStrategyFailure,
} from './strategyRedux';


 // Fetch all strategies
export const getStrategies = async (dispatch) => {
  dispatch(getStrategiesStart());
  try {
    const res = await publicRequest.get("/strategies");
    dispatch(getStrategiesSuccess(res.data));
  } catch (err) {
    dispatch(getStrategiesFailure());
  }
};

// Add a new strategy
export const addStrategy = async (strategy, dispatch) => {
  dispatch(addStrategyStart());
  try {
    const res = await userRequest().post("/strategies", strategy);
    dispatch(addStrategySuccess(res.data));
  } catch (err) {
    dispatch(addStrategyFailure());
  }
};

// Update an existing strategy
export const updateStrategy = async (id, strategy, dispatch) => {
  dispatch(updateStrategyStart());
  try {
    const res = await publicRequest.put(`/strategies/${id}`, strategy);
    dispatch(updateStrategySuccess({ ...strategy, _id: id }));
  } catch (err) {
    dispatch(updateStrategyFailure());
  }
};

// Delete a strategy
export const deleteStrategy = async (id, dispatch) => {
  dispatch(deleteStrategyStart());
  try {
    await userRequest().delete(`/strategies/${id}`);
    dispatch(deleteStrategySuccess(id));
  } catch (err) {
    dispatch(deleteStrategyFailure());
  }
};
  
//Pricing Packages
  export const getPricingPackages = async (dispatch) => {
    dispatch(getPricingPackagesStart());
    try {
      const res = await publicRequest.get("/pricing-packages");
      dispatch(getPricingPackagesSuccess(res.data));
    } catch (err) {
      dispatch(getPricingPackagesFailure());
    }
  };
  
  export const deletePricingPackage = async (id, dispatch) => {
    dispatch(deletePricingPackageStart());
    try {
      await userRequest().delete(`/pricing-packages/${id}`);
      dispatch(deletePricingPackageSuccess(id));
    } catch (err) {
      dispatch(deletePricingPackageFailure());
    }
  };
  
  export const updatePricingPackage = async (id, pricingPackage, dispatch) => {
    dispatch(updatePricingPackageStart());
    try {
      const res = await userRequest().put(`/pricing-packages/${id}`, pricingPackage);
      dispatch(updatePricingPackageSuccess({ id, package: pricingPackage }));
    } catch (err) {
      dispatch(updatePricingPackageFailure());
    }
  };

  export const addPricingPackage =(pricePackage) => async (dispatch) => {
    dispatch(addPricingPackageStart());
    try {
      const res = await userRequest().post("/pricing-packages", pricePackage);
      dispatch(addPricingPackageSuccess(res.data));
    } catch (err) {
      console.error("Error adding card:", err);
      dispatch(addPricingPackageFailure());
    }
  };
  
  



  export const getAgreements = async (dispatch) => {
    dispatch(getAgreementsStart());
    try {
      const res = await publicRequest.get("/agreements");
      dispatch(getAgreementsSuccess(res.data));
    } catch (err) {
      dispatch(getAgreementsFailure());
    }
  };
  
 
  export const deleteAgreement = async (id, dispatch) => {
    dispatch(deleteAgreementStart());
    try {
      await userRequest().delete(`/agreements/${id}`);
      dispatch(deleteAgreementSuccess(id));
    } catch (err) {
      dispatch(deleteAgreementFailure());
    }
  };

  
  export const updateAgreement = async (id, agreement, dispatch) => {
    dispatch(updateAgreementStart());
    try {
      const res = await userRequest().put(`/agreements/${id}`, agreement);
      dispatch(updateAgreementSuccess({ id, agreement }));
    } catch (err) {
      dispatch(updateAgreementFailure());
    }
  };
 
  
  export const addAgreement = (agreement) => async (dispatch) => {
    dispatch(addAgreementStart());
    try {
      const res = await userRequest().post("/agreements", agreement);
      dispatch(addAgreementSuccess(res.data));
    } catch (err) {
      dispatch(addAgreementFailure());
    }
  };
  


  

  export const addIndustry =(industry) => async (dispatch) => {
    dispatch(addIndustryStart());
    try {
      const res = await publicRequest.post("/industries", industry);
      dispatch(addIndustrySuccess(res.data));
    } catch (err) {
      dispatch(addIndustryFailure());
    }
  };
 
  export const deleteIndustry = async (id, dispatch) => {
    dispatch(deleteIndustryStart());
    try {
      await userRequest().delete(`/industries/${id}`);
      dispatch(deleteIndustrySuccess(id));
    } catch (err) {
      dispatch(deleteIndustryFailure());
    }
  };
    
  export const updateIndustry = async (id, industry, dispatch) => {
    dispatch(updateIndustryStart());
    try {
      const res = await userRequest().put(`/industries/${id}`, industry);
      dispatch(updateIndustrySuccess(res.data));
    } catch (err) {
      dispatch(updateIndustryFailure());
    }
  };
  export const getIndustries = async (dispatch) => {
    dispatch(getIndustriesStart());
    try {
      const res = await userRequest().get("/industries");
      dispatch(getIndustriesSuccess(res.data));  // Confirm that the data structure matches expected format
    } catch (err) {
      console.error("Error fetching industries:", err);
      dispatch(getIndustriesFailure());
    }
  };
  
  export const getProposals = async (dispatch) => {
    dispatch(fetchProposalsStart());
    try {
      const res = await publicRequest.get("/proposals");
      dispatch(fetchProposalsSuccess(res.data));
    } catch (err) {
      dispatch(fetchProposalsFailure());
    }
  };
  
  export const addProposal = (proposal) => async (dispatch) => {
    dispatch(addProposalStart());
    try {
      const res = await userRequest().post("/proposals", proposal);
      dispatch(addProposalSuccess(res.data));
    } catch (err) {
      dispatch(addProposalFailure());
    }
  };
  
  export const updateProposal = async (id, proposal, dispatch) => {
    dispatch(updateProposalStart());
    try {
      const res = await userRequest().put(`/proposals/${id}`, proposal);
      dispatch(updateProposalSuccess(res.data));
    } catch (err) {
      dispatch(updateProposalFailure());
    }
  };
  
  export const softDeleteProposal = async (id, dispatch) => {
    dispatch(deleteProposalStart());
    try {
      const res = await publicRequest.delete(`/proposals/${id}`);
      if (res.status === 200) {
        // Dispatch success with the ID of the deleted proposal to remove it from state
        dispatch(deleteProposalSuccess(id));  
      }
    } catch (err) {
      dispatch(deleteProposalFailure());
    }
};


export const restoreProposal = async (id, dispatch) => {
    dispatch(updateProposalStart());
    try {
      const res = await publicRequest.put(`/proposals/restore/${id}`);
      dispatch(updateProposalSuccess(res.data));
    } catch (err) {
      dispatch(updateProposalFailure());
    }
};

export const permanentlyDeleteProposal = async (id, dispatch) => {
    dispatch(deleteProposalStart());
    try {
      await publicRequest.delete(`/proposals/permanent/${id}`);
      dispatch(deleteProposalSuccess(id));  // This now actually removes the proposal
    } catch (err) {
      dispatch(deleteProposalFailure());
    }
};
export const getDeletedProposals = async (dispatch) => {
  dispatch(fetchProposalsStart());
  try {
    const res = await publicRequest.get("/proposals/trash");  // Assuming this endpoint returns only soft-deleted proposals
    dispatch(fetchProposalsSuccess(res.data));
  } catch (err) {
    dispatch(fetchProposalsFailure());
  }
};

export const getSingleProposal = (id) => async (dispatch) => {
  dispatch(fetchProposalStart());
  try {
      const res = await publicRequest.get(`/proposals/${id}`);
      dispatch(fetchProposalSuccess(res.data)); // Ensure this line correctly handles the response
  } catch (err) {
      dispatch(fetchProposalFailure());
  }
};
